import { render, staticRenderFns } from "./mandv.vue?vue&type=template&id=2a534777&scoped=true&"
var script = {}
import style0 from "./mandv.vue?vue&type=style&index=0&id=2a534777&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a534777",
  null
  
)

export default component.exports